.is-clickable:hover {
    cursor: pointer;
}

.candidate-card-content {
    display: grid;
    grid-template-columns: 34% 66%;
    grid-template-rows: 100%;
}

.candidate-card-photo {
    max-width: 100%;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.section--stop-training {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 767px) {
    .candidate-card-content {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 50% 50%;
    }

    .candidate-card-photo {
        margin-right: 0;
    }

    .candidate-card-photo img {
        max-height: 150px;
    }
}

